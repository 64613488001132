<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('取款记录')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <template v-for="(item, index) in list">
                        <div :key="index" class="wt-card">
                            <div class="wt-status" :class="item.status == 3 ? 'success' : (item.status == -1 ? 'danger': 'info')">
                                {{$t(item.status_text) }}
                            </div>
                            <div class="wt-items">
                                <div class="label">{{$t('取款金额')}}：</div>
                                <div class="value text-green" v-if="item.type == 1">{{parseFloat(parseFloat(item.money) +
                                    parseFloat(item.server_money)).toFixed(2)}} {{$t('USDT')}}<span style="font-size: 12px"> </span>
                                </div>
                                <div class="value text-green" v-else>{{parseFloat((parseFloat(item.money) +
                                    parseFloat(item.server_money)) ).toFixed(2)}} {{$t('USDT')}}
                                </div>
                            </div>
                            <div class="wt-items">
                                <div class="label">{{$t('手续费')}}：</div>
                                <div class="value" v-if="item.type == 1">
                                    {{parseFloat(item.server_money).toFixed(2)}} {{$t('USDT')}}
                                </div>
                                <div class="value" v-else>
                                    {{parseFloat(item.server_money ).toFixed(2)}} {{$t('USDT')}}
                                </div>
                            </div>
                            <div class="wt-items">
                                <div class="label">{{$t('取款渠道')}}：</div>
                                <div class="value" v-if="item.type == 1" style="font-size: 12px">
                                    {{item.bankinfo_bank_name}}
                                </div>
                                <div class="value" v-else style="font-size: 12px">{{item.bankinfo_bank_name}}</div>
                            </div>
                            <div class="wt-items">
                                <div class="label">{{$t('申请时间')}}：</div>
                                <div class="time">{{item.created_at}}</div>
                            </div>
                            <div class="wt-intro" v-if="item.status == -1 && item.reply">
                                <div class="label">{{$t('反馈')}}：</div>
                                <div class="bd">{{item.reply}}</div>
                            </div>
                        </div>
                    </template>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get(this.$api_url.withdraw_list + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        console.log(this.list);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        }

    }
</script>
